import axios from 'axios'
import { gql } from 'graphql-request'
import {
  Task as TaskType,
  TrackCategory,
  TrackFileProvider,
  TrackOrderField,
  TrackOrderSort,
  TrackScope
} from '../../../types'
import { UploadFiles } from '../../../types/upload.types'
import {
  TaskFileUploaded,
  TaskOperations
} from '../../../hooks/upload/use-create-task/types'

interface TaskCli {
  uploadUrl(data: { url: any }): Promise<any>
  uploadFile(data: { file: string }): Promise<any>
  uploadFiles(data: {
    count: number
    type: UploadFiles['provider']
    resumable?: boolean
  }): Promise<UploadFiles>
  createTask(data: {
    taskFile: TaskFileUploaded
    operations: TaskOperations[]
  }): Promise<string>
  updateTrack(data: { trackId: string; name: string }): Promise<any>
  reprocessOperation(data: {
    id: string
    supportedOperation: {
      name: string
      params: any
    }
  }): Promise<any>
  addOperationsToTask(data: { trackId: any; operations: any }): Promise<any>
  fetchTask(i: { taskId: string }): Promise<TaskType>
  fetchTasks(i: {
    offset: number
    limit: number
    searchQuery: string
    scope?: TrackScope[]
    category?: TrackCategory
    fileProvider?: TrackFileProvider
    field?: TrackOrderField
    sort?: TrackOrderSort
  }): Promise<any>
  signTempComposerJwt(): Promise<any>
  signZendeskAuthJwt(): Promise<any>
  signOutTempComposerJwt(): Promise<any>
  updateTrackMetadata(data: {
    trackId: any
    key: any
    value: any
  }): Promise<any>
  updateTrackInfo({
    id,
    data
  }: {
    id: string
    data: {
      artist: string
      title: string
      album: string
      genre: string
      comment: string | null
    }
  }): Promise<any>
}

const Task = (graphQL: any): TaskCli => {
  const uploadFile = async ({ file }: { file: any }): Promise<any> => {
    const query = gql`
            mutation UploadFile {
                uploadFile(input:"${file.name}", type:FILESYSTEM ){
                    tempLocation
                    signedUrl
                    name
                    input
                }
            }
        `

    const result = await graphQL({ query })

    await axios({
      url: result.uploadFile.signedUrl,
      method: 'PUT',
      // headers: { 'Content-Type': file.type },
      data: file
    })

    delete result.uploadFile.signedUrl

    result.uploadFile.provider = 'FILESYSTEM'
    return result.uploadFile
  }

  const uploadFiles: TaskCli['uploadFiles'] = async ({
    count,
    type,
    resumable
  }) => {
    const variables = {
      count,
      type,
      resumable
    }

    const query = gql`
      mutation UploadFiles(
        $count: Int
        $type: FileProvider
        $resumable: Boolean
      ) {
        uploadFiles(count: $count, type: $type, resumable: $resumable) {
          signedUrls {
            signedUrl
            tempLocation
          }
          provider
        }
      }
    `
    const result = await graphQL({ query, variables })
    return result?.uploadFiles
  }

  const uploadUrl = async ({ url }: { url: string }): Promise<any> => {
    const query = gql`
            mutation UploadFile {
                uploadFile(input:"${url}", type:URL){
                    tempLocation
                    name
                    input
                }
            }
        `

    const result = await graphQL({ query })

    result.uploadFile.provider = 'URL'
    return result.uploadFile
  }

  const createTask: TaskCli['createTask'] = async ({
    taskFile,
    operations
  }) => {
    const opsGql = operations.map((op) => {
      return `{ name: ${op.name} params: ${JSON.stringify(op.params)}}`
    })

    const mutation = gql`
            mutation CreateTask {
                createTask(
                    file: {
                    provider: ${taskFile.provider}
                    tempLocation: "${taskFile.tempLocation}"
                    name: "${taskFile.name.replace(/["“”]/g, '\\"')}"
                    input: "${taskFile.input.replace(/["“”]/g, '\\"')}"
                    }
                    operations: [${opsGql}]
                )
            }
        `

    const unquotedQuery = mutation.replace(/"([^"]+)":/g, '$1:')

    const result = await graphQL({ query: unquotedQuery })

    return result.createTask
  }

  const updateTrack = async ({
    trackId,
    name
  }: {
    trackId: string
    name: string
  }): Promise<any> => {
    const variables = {
      id: trackId,
      data: {
        name
      }
    }
    const query = gql`
      mutation UpdateTrack($id: ID!, $data: UpdateTrackInput!) {
        updateTrack(id: $id, data: $data) {
          id
          name
        }
      }
    `
    const result = await graphQL({ query, variables })
    return result
  }

  const reprocessOperation = async ({
    id,
    supportedOperation: { name, params }
  }: {
    id: string
    supportedOperation: {
      name: string
      params: any
    }
  }): Promise<any> => {
    const variables = {
      id,
      supportedOperation: { name, params: params ?? {} }
    }
    const query = gql`
      mutation ReprocessOutdatedOperation(
        $id: ID!
        $supportedOperation: OperationInput!
      ) {
        reprocessOutdatedOperation(
          id: $id
          supportedOperation: $supportedOperation
        )
      }
    `
    const result = await graphQL({ query, variables })
    return result
  }

  const addOperationsToTask = async ({
    trackId,
    operations
  }: {
    trackId: string
    operations: any
  }): Promise<any> => {
    const variables = {
      trackId,
      operations
    }
    const query = gql`
      mutation AddOperationsToTrack(
        $trackId: ID!
        $operations: [OperationInput!]!
      ) {
        addOperationsToTrack(trackId: $trackId, operations: $operations)
      }
    `
    const result = await graphQL({ query, variables })
    return result
  }

  const fetchTasks = async ({
    offset,
    limit,
    searchQuery = '',
    scope = ['MY_SONGS', 'SHARED_SONGS'],
    category,
    fileProvider,
    field = 'RECENT',
    sort = 'DESC'
  }: {
    offset: number
    limit: number
    searchQuery: string
    scope?: TrackScope[]
    category?: TrackCategory
    fileProvider?: TrackFileProvider
    field?: TrackOrderField
    sort?: TrackOrderSort
  }): Promise<any> => {
    const variables = {
      pagination: {
        offset,
        limit
      },
      filters: {
        searchQuery,
        scope,
        category,
        fileProvider
      },
      sorting: {
        field,
        sort
      }
    }

    const query = gql`
      query TracksV2(
        $pagination: Pagination!
        $filters: TrackFilterV2
        $sorting: TrackOrdering
      ) {
        tracksV2(
          pagination: $pagination
          filters: $filters
          sorting: $sorting
        ) {
          totalCount
          edges {
            node {
              id
              isOwner
              isDemo
              category
              file {
                name
                provider
              }
              operations {
                id
                name
                status
                statusReason
                createdAt
                startedAt
                completedAt
                isOwner
              }
              isShared
              owner
              summary {
                v1 {
                  original {
                    duration
                    bitRate
                    bpm
                    key
                    cover
                    artist
                    title
                    genre
                    sampleRate
                  }
                }
              }
            }
          }
        }
      }
    `
    const result = await graphQL({ query, variables })
    return result
  }

  const fetchTask = async ({
    taskId
  }: {
    taskId: string
  }): Promise<TaskType> => {
    const variables = {
      taskId
    }

    const query = gql`
      query Track($taskId: String!) {
        track(id: $taskId) {
          id
          isDemo
          isPublic
          isOwner
          metadata {
            id
            key
            value
          }
          file {
            name
            input
            location
            duration
          }
          operations {
            id
            name
            status
            createdAt
            startedAt
            completedAt
            params
            current
            outdated
            outdatedReason
            updateInProgress
            isOwner
            result
          }
          playlists {
            id
            name
            type
            viewOnly
          }
          posts {
            totalCount
            notes {
              id
              content
              name
              avatar
              createdAt
              attachments {
                url
                name
              }
              video {
                title
                thumbnail
                video
                duration
                orientation
              }
            }
          }
        }
      }
    `
    const result = await graphQL({ query, variables })
    return result.track
  }

  const signTempComposerJwt = async (): Promise<any> => {
    const query = gql`
      mutation SignTempComposerJwt {
        signTempComposerJwt
        newCookie: signTempComposerJwt(newCookie: true)
      }
    `

    const result = await graphQL({ query, variables: {} })
    return result
  }

  const signZendeskAuthJwt = async (): Promise<any> => {
    const query = gql`
      mutation SignZendeskLoginJwt {
        signZendeskLoginJwt
      }
    `
    const result = await graphQL({ query, variables: {} })
    return result
  }

  const signOutTempComposerJwt = async (): Promise<any> => {
    const query = gql`
      mutation DeleteTempComposerJwt {
        deleteTempComposerJwt
      }
    `
    const result = await graphQL({ query, variables: {} })
    return result
  }

  const updateTrackMetadata = async ({
    trackId,
    key,
    value
  }: {
    trackId: string
    key: string
    value: any
  }): Promise<any> => {
    const variables = {
      trackId,
      key,
      value
    }
    const query = gql`
      mutation UpdateTrackMetadata(
        $trackId: String!
        $key: String!
        $value: JSON!
      ) {
        updateTrackMetadata(trackId: $trackId, key: $key, value: $value)
      }
    `
    const result = await graphQL({ query, variables })
    return result
  }

  const updateTrackInfo = async ({
    id,
    data
  }: {
    id: string
    data: {
      artist: string
      title: string
      album: string
      genre: string
      comment: string | null
    }
  }): Promise<any> => {
    const variables = {
      id,
      data
    }
    const query = gql`
      mutation UpdateTrackInfo($id: String!, $data: UpdateTrackInfoInput!) {
        updateTrackInfo(id: $id, data: $data) {
          id
          artist
          title
          album
          genre
          comment
        }
      }
    `
    const result = await graphQL({ query, variables })
    return result
  }

  return {
    uploadUrl,
    uploadFile,
    uploadFiles,
    createTask,
    updateTrack,
    reprocessOperation,
    addOperationsToTask,
    signTempComposerJwt,
    signZendeskAuthJwt,
    signOutTempComposerJwt,
    fetchTasks,
    fetchTask,
    updateTrackMetadata,
    updateTrackInfo
  }
}

export default Task
