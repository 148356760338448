'use client'

import Script from 'next/script'
import { getEnv } from '../../config'

export const Scripts: React.FC = () => (
  <>
    {/* rudderstack lib */}
    <Script
      id="rudderstack-lib"
      src={`/assets/javascript/rudderanalytics${
        getEnv() === 'production' ? '' : '-stage'
      }.min.js`}
      strategy="beforeInteractive"
    />
    {/* hotjar */}
    <Script
      id="hotjar"
      strategy="beforeInteractive"
      dangerouslySetInnerHTML={{
        __html: `
        (function (h, o, t, j, a, r) {
          h.hj =
            h.hj ||
            function () {
              (h.hj.q = h.hj.q || []).push(arguments);
            };
          h._hjSettings = { hjid: 1769790, hjsv: 6 };
          a = o.getElementsByTagName("head")[0];
          r = o.createElement("script");
          r.async = 1;
          r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
          a.appendChild(r);
        })(window, document, "https://static.hotjar.com/c/hotjar-", ".js?sv=");
        `
      }}
    />
    {/* facebook pixel */}
    <Script
      id="fbPixel"
      strategy="beforeInteractive"
      dangerouslySetInnerHTML={{
        __html: `
          !function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('track', 'PageView');
          `
      }}
    />

    {/* Customer.io SDK */}
    <Script
      id="cioSDK"
      strategy="beforeInteractive"
      dangerouslySetInnerHTML={{
        __html: `
          var _cio = _cio || [];
          (function () {
            var a, b, c;
            a = function (f) {
              return function () {
                _cio.push([f].concat(Array.prototype.slice.call(arguments, 0)));
              };
            };
            b = ["load", "identify", "sidentify", "track", "page", "on", "off"];
            for (c = 0; c < b.length; c++) {
              _cio[b[c]] = a(b[c]);
            }
            var t = document.createElement("script"),
              s = document.getElementsByTagName("script")[0];
            t.async = true;
            t.id = "cio-tracker";
            t.setAttribute("data-site-id", "35d6f537c6cc06d8bdaa");
            t.setAttribute("data-use-array-params", "true");
            t.setAttribute("data-use-in-app", "true");
            t.src = "https://assets.customer.io/assets/track.js";
            //If your account is in the EU, use:
            //t.src = 'https://assets.customer.io/assets/track-eu.js'
            s.parentNode.insertBefore(t, s);
          })();
          `
      }}
    />

    <Script
      id="gTagManager"
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                           new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                           j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                           'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                           })(window,document,'script','dataLayer','GTM-56LD7X6');`
      }}
    />
    <noscript>
      <iframe
        src="https://www.googletagmanager.com/ns.html?id=GTM-56LD7X6"
        height="0"
        width="0"
        title="moises"
        // eslint-disable-next-line react/style-prop-object
        style={{ display: 'none', visibility: 'hidden' }}
      />
    </noscript>
  </>
)
